export const FETCH_USER = 'fetch_user';
export const FETCHING_USER = 'fetching_user';
export const FETCHING_SUB_DOMAIN_DATA = 'fetching_sub_domain_data';
export const FETCH_SUB_DOMAIN_DATA = 'fetch_sub_domain_data';
export const USER_LOGOUT = 'user_logout';
export const TOKEN_EXPIRED = 'token_expired';

export const CARDS_FETCHING = 'cards_fetching';
export const CARDS_FETCHED = 'cards_fetched';

export const FETCHING_ALL_USERS = 'fetching_all_users';
export const FETCH_ALL_USERS = 'fetch_all_users';
export const ADD_USER = 'add_user';
export const ADD_CUSTOMER = 'add_customer';
export const REMOVE_USER = 'remove_user';
export const REMOVE_CUSTOMER = 'remove_customer';
export const REMOVE_WHITE_LABEL_CUSTOMER = 'remove_white_label_customer';
export const FETCH_ALL_CUSTOMERS = 'fetch_all_customers';
export const FETCHING_ALL_CUSTOMERS = 'fetching_all_customers';
export const FETCH_ALL_WHITE_LABEL_CUSTOMERS = 'fetch_all_white_label_customers';
export const FETCHING_ALL_WHITE_LABEL_CUSTOMERS = 'fetching_all_white_label_customers';

export const FETCHING_SETTINGS = 'fetching_settings';
export const FETCH_SETTINGS = 'fetch_settings';
export const UPDATE_SETTINGS = 'update_settings';

export const YTHELPER_CARDS_FETCHING = 'ythelper_cards_fetching';
export const YTHELPER_CARDS_FETCHED = 'ythelper_cards_fetched';

export const FETCHING_NOTIFICATION_COUNT = 'fetching_notification_count';
export const FETCH_NOTIFICATION_COUNT = 'fetch_notification_count';
export const FETCHING_NOTIFICATION_LIST = 'fetching_notification_list';
export const FETCH_NOTIFICATION_LIST = 'fetch_notification_list';
