import {axiosClient} from '../api/httpClient';

export const fetchCardsBasedOnSection = async (obj) => {
	try {
		const data = await axiosClient.post('/api/getCards', obj);
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const getCardsBasedOnTitle = async (title) => {
	try {
		const data = await axiosClient.get('/api/getCardsByTitle', {
            params: {
				title: title
			},
        });
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const saveNewQAOnCard = async (obj) => {
	try {
		const data = await axiosClient.post('/api/assignNewQA', obj);
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const addEventsForCard = (eventType, cardId) => {
	return axiosClient.get(`/api/add-card-event/${cardId}?type=${eventType}`) 
}
